<template>
  <div class="under">
    <div class="name">{{ info.title }}</div>

    <div class="under-status">
      <van-image
        width="95"
        height="22"
        :src="require(`@/assets/images/${type}line-under.png`)"
      ></van-image>

      <van-image
        width="97"
        v-if="status"
        :src="require(`@/assets/images/${status}.png`)"
      ></van-image>
    </div>

    <div class="card">
      <div class="company">
        <p>
          <span>招聘企业</span>
          <span class="small">(已通过)</span>
        </p>

        <p>
          <span class="big">{{ info.companyNumber }}</span>
          <span>家</span>
        </p>
      </div>

      <div class="job">
        <p>
          <span>招聘职位</span>
          <span class="small">(已通过)</span>
        </p>

        <p>
          <span class="big">{{ info.positionNumber }}</span>
          <span>个</span>
        </p>
      </div>

      <div class="job">
        <p>
          <span>招聘人数</span>
          <span class="small">(已通过)</span>
        </p>

        <p>
          <span class="big">{{ info.peopleNumber }}</span>
          <span>人</span>
        </p>
      </div>
    </div>

    <van-tabs
      class="tab"
      v-model:active="active"
      @click-tab="clickDisabledActive"
    >
      <van-tab title="双选会详情" name="0"></van-tab>
      <van-tab
        title="人才大厅"
        name="1"
        v-if="info.schoolId && studentList.length"
        :disabled="info.status == 2"
      ></van-tab>
      <van-tab title="企业大厅" name="2"></van-tab>
      <van-tab title="职位大厅" name="3"></van-tab>
    </van-tabs>

    <div class="detail" v-show="active == 0">
      <div class="sponsor">
        <div
          class="sponsor-line"
          v-if="info.guideUnit && info.guideUnit.trim()"
        >
          <div class="title">指导单位：</div>

          <p class="value">{{ info.guideUnit }}</p>
        </div>

        <div class="sponsor-line">
          <div class="title">主办方：</div>

          <p class="value">{{ info.sponsor }}</p>
        </div>

        <div
          class="sponsor-line"
          v-if="info.organizer && info.organizer.trim()"
        >
          <div class="title">承办方：</div>

          <p class="value">{{ info.organizer }}</p>
        </div>

        <div
          class="sponsor-line"
          v-if="info.coSponsor && info.coSponsor.trim()"
        >
          <div class="title">协办方：</div>

          <p class="value">{{ info.coSponsor }}</p>
        </div>

        <div
          class="sponsor-line"
          v-if="info.technicalSupport && info.technicalSupport.trim()"
        >
          <div class="title">技术支持：</div>

          <p class="value">{{ info.technicalSupport }}</p>
        </div>
      </div>

      <div class="info">
        <div class="block">
          <p>
            <van-icon :name="require('@/assets/images/level.png')"></van-icon>
            {{ info.schoolLevel }}
          </p>

          <p>
            <van-icon
              :name="require('@/assets/images/location-fill.png')"
            ></van-icon>
            <span class="address">{{ info.address }}</span>
          </p>
        </div>

        <div class="sign">
          <div class="left">
            <span>报名开始时间：</span>
            <span class="time">{{ dateText(info.signStart) }}</span>
          </div>

          <van-icon :name="require('@/assets/images/to.png')"></van-icon>

          <div class="left">
            <span>报名结束时间：</span>
            <span class="time">{{ dateText(info.signEnd) }}</span>
          </div>
        </div>

        <div class="sign">
          <div class="left">
            <span>双选会开始时间：</span>
            <span class="time">{{ dateText(info.startDate) }}</span>
          </div>

          <van-icon :name="require('@/assets/images/to.png')"></van-icon>

          <div class="left">
            <span>双选会结束时间：</span>
            <span class="time">{{ dateText(info.endDate) }}</span>
          </div>
        </div>
      </div>

      <div class="invitation" v-if="info.invitation">
        <div class="title">企业邀请函</div>

        <div class="ql-editor" v-html="info.invitation"></div>
      </div>

      <div class="button-wrapper">
        <div class="button">
          <div class="tip" @click="jump">
            <van-icon name="question" />
            操作流程
          </div>

          <van-button
            block
            round
            type="danger"
            :disabled="info.status == 2"
            @click="downloadHandler('company')"
            >{{ info.status == 2 ? '已结束' : '用人单位报名' }}</van-button
          >

          <van-button
            block
            round
            type="danger"
            class="stu-btn"
            :disabled="info.status == 2"
            @click="active = '3'"
            >{{ info.status == 2 ? '已结束' : '学生免费参会' }}</van-button
          >
        </div>
      </div>
    </div>

    <div class="student" v-show="active == 1">
      <van-sticky v-if="schoolList.length">
        <van-dropdown-menu class="single">
          <van-dropdown-item v-model="schoolId" :options="schoolList" />
        </van-dropdown-menu>
      </van-sticky>

      <van-list
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="getStudent"
        :immediate-check="false"
      >
        <div v-for="item in studentList" :key="item.id" class="student-item">
          <van-image
            class="edu"
            :src="
              require(`@/assets/images/${
                item.educationBackground == 3
                  ? 'benke'
                  : item.educationBackground == 4
                  ? 'shuoshi'
                  : item.educationBackground == 5
                  ? 'boshi'
                  : 'zhuanke'
              }.png`)
            "
          ></van-image>

          <div class="flex-box">
            <div class="avatar">
              <van-image
                round
                width="44"
                height="44"
                :src="
                  item.url ||
                  require(`@/assets/images/${
                    item.gender == 1 ? '' : 'fe'
                  }male.png`)
                "
              />
              <van-icon
                :name="
                  require(`@/assets/images/${
                    item.gender == 1 ? '' : 'fe'
                  }male-icon.png`)
                "
              ></van-icon>
            </div>

            <div class="student">
              <div class="real-name">{{ item.realname }}</div>

              <div class="major">
                {{ item.majorName }}-{{ item.schoolName }}
              </div>
            </div>
          </div>

          <div class="button">
            <van-button
              round
              type="warning"
              size="mini"
              @click="downloadHandler('company')"
              >打赏求面试</van-button
            >

            <van-button
              round
              type="warning"
              size="mini"
              @click="downloadHandler('company')"
              >打招呼</van-button
            >
          </div>
        </div>
      </van-list>
    </div>

    <div class="company" v-show="active == 2">
      <van-sticky>
        <van-dropdown-menu>
          <van-dropdown-item
            title="所处行业"
            v-model="industryId"
            :options="industryList"
          >
            <template v-slot:title>
              <span>{{
                industryId
                  ? industryList.find((item) => item.value == industryId).text
                  : '所处行业'
              }}</span>
            </template>
          </van-dropdown-item>
          <van-dropdown-item v-model="scaleId" :options="scaleList">
            <template v-slot:title>
              <span>{{
                scaleId
                  ? scaleList.find((item) => item.value == scaleId).text
                  : '企业规模'
              }}</span>
            </template>
          </van-dropdown-item>
          <van-dropdown-item v-model="natureId" :options="natureList">
            <template v-slot:title>
              <span>{{
                natureId
                  ? natureList.find((item) => item.value == natureId).text
                  : '企业性质'
              }}</span>
            </template>
          </van-dropdown-item>
        </van-dropdown-menu>
      </van-sticky>

      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="getCompany"
        :immediate-check="false"
      >
        <div
          v-for="item in companyList"
          :key="item.id"
          class="company-item"
          @click="$router.push(`/companyShare/?id=${item.companyId}`)"
        >
          <van-image
            class="left"
            round
            width="50"
            height="50"
            :src="item.logo || require('@/assets/images/company-icon.png')"
            @error="item.logo = require('@/assets/images/company-icon.png')"
          >
          </van-image>
          <div class="right">
            <div class="company-name">{{ item.companyName }}</div>
            <div class="tags">
              <van-tag v-if="item.indName">{{ item.indName }}</van-tag>
              <van-tag v-if="item.natureName">{{ item.natureName }}</van-tag>
              <van-tag v-if="item.scale">{{ scaleText(item.scale) }}</van-tag>
            </div>
          </div>
        </div>
      </van-list>
    </div>

    <div class="job" v-show="active == 3">
      <van-sticky>
        <van-search
          v-model="searchStr"
          show-action
          placeholder="请输入职位/公司"
          @search="searchJob"
        >
          <template #action>
            <div @click="searchJob">搜索</div>
          </template>
        </van-search>

        <van-dropdown-menu>
          <van-dropdown-item v-model="sort" :options="sortList" />

          <van-dropdown-item ref="cityDown">
            <template v-slot:title>
              <span>{{ cityId ? cityText : '城市' }}</span>
            </template>

            <div class="city-inner">
              <van-tree-select
                :items="cityList"
                v-model:active-id="choosenCityId"
                v-model:main-active-index="parentCityIdx"
                @click-item="chooseCity"
              />
            </div>
          </van-dropdown-item>

          <van-dropdown-item ref="typeDown">
            <template v-slot:title>
              <span>{{ typeId ? typeText : '职位类型' }}</span>
            </template>

            <div class="type-inner">
              <van-tree-select
                :items="typeList"
                v-model:active-id="outTypeId"
                v-model:main-active-index="inTypeId"
                @click-item="chooseType"
              />
            </div>
          </van-dropdown-item>

          <van-dropdown-item title="要求" ref="optDown">
            <div class="option-inner">
              <div class="title">薪资</div>
              <div class="opts">
                <span
                  class="opt-item"
                  v-for="item in salaryList"
                  :key="item.id"
                  :class="{ checked: salary == item.id }"
                  @click="salary = salary == item.id ? '' : item.id"
                  >{{ item.text }}</span
                >
              </div>
              <div class="title">学历</div>
              <div class="opts">
                <span
                  class="opt-item"
                  v-for="item in eduList"
                  :key="item.id"
                  :class="{ checked: educationalBackground == item.id }"
                  @click="
                    educationalBackground =
                      educationalBackground == item.id ? '' : item.id
                  "
                  >{{ item.text }}</span
                >
              </div>
              <div class="button">
                <van-button
                  type="danger"
                  size="small"
                  block
                  round
                  @click="chooseOpt"
                >
                  确认
                </van-button>
              </div>
            </div>
          </van-dropdown-item>
        </van-dropdown-menu>
      </van-sticky>

      <van-list
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="getJob"
        :immediate-check="false"
      >
        <div
          class="job-item"
          v-for="item in jobList"
          :key="item.id"
          @click="$router.push(`/jobShare?id=${item.id}`)"
        >
          <div class="job-1">
            <div class="left">
              <span>{{ item.name }}</span>
              <van-tag v-if="item.hot" type="warning">热门</van-tag>
            </div>

            <div class="right">
              {{ salaryText(item.salary) }}
            </div>
          </div>
          <div class="job-2">
            <span>{{ eduText(item.educationalBackground) }}</span
            ><span>{{ item.cityName }}</span>
          </div>
          <div class="job-3">
            <span>{{ item.companyName }}</span>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import {
  Notify,
  Dialog,
  Image,
  Tabs,
  Tab,
  Tag,
  List,
  Icon,
  Button,
  DropdownItem,
  DropdownMenu,
  Sticky,
  Search,
  TreeSelect,
} from 'vant';
import moment from 'moment';
import {
  getByUndersId,
  getSchoolByUndersId,
  getIndustryList,
  getNatureList,
  getPosInfoList,
  getAreaList,
  getStudentList,
  querySignupList,
  getUndersProfession,
} from '@/api/api';
import wxShare from '@/composables/wx';
import download from '@/composables/download';
import filters from '@/composables/filters';

export default {
  name: 'Under',

  setup() {
    const { wxShareHandler } = wxShare();
    const { downloadHandler } = download();
    const { salaryText, eduText } = filters();

    return {
      wxShareHandler,
      downloadHandler,
      salaryText,
      eduText,
    };
  },

  data() {
    return {
      id: this.$route.query.id || this.$route.query.stuId,
      info: {},
      active: 0,
      pageNum: 1,
      loading: false,
      finished: false,

      studentList: [],
      companyList: [],
      jobList: [],

      schoolId: '',
      schoolList: [],
      industryId: '',
      industryList: [],
      scaleId: '',
      scaleList: [
        { text: '不限', value: '' },
        { text: '10人以下', value: '1' },
        { text: '10~20人', value: '2' },
        { text: '20~50人', value: '3' },
        { text: '50~200人', value: '4' },
        { text: '200~1000人', value: '5' },
        { text: '1000以上', value: '6' },
      ],
      natureId: '',
      natureList: [],
      searchStr: '',
      sort: '1',
      sortList: [
        { text: '热门', value: '1' },
        { text: '最新', value: '2' },
      ],
      choosenCityId: 0,
      parentCityIdx: 0,
      cityId: '',
      cityType: '',
      cityText: '',
      cityList: [],
      outTypeId: 0,
      inTypeId: 0,
      typeId: '',
      typeText: '',
      typeList: [],
      salary: '',
      salaryList: [
        { id: '2', text: '3-4k' },
        { id: '3', text: '4-5k' },
        { id: '4', text: '5-10k' },
        { id: '5', text: '10-20k' },
        { id: '6', text: '20-50k' },
        { id: '7', text: '50k以上' },
      ],
      educationalBackground: '',
      eduList: [
        { id: '3', text: '本科' },
        { id: '4', text: '硕士' },
        { id: '5', text: '博士' },
      ],
    };
  },

  computed: {
    type() {
      return this.info.type == 1 ? 'on' : 'off';
    },

    status() {
      if (Object.keys(this.info).length == 0) {
        return '';
      } else if (this.info.status == 0) {
        return 'ing';
      } else if (this.info.status == 1) {
        return 'will';
      } else {
        return 'end';
      }
    },

    schoolName() {
      return (
        (this.schoolList.find((item) => item.id == this.schoolId) || {}).name ||
        '选择打招呼职位'
      );
    },
  },

  watch: {
    active(val) {
      this.loading = true;
      this.finished = false;
      this.pageNum = 1;

      this.studentList = this.studentList.slice(0, 10);
      this.companyList = [];
      this.jobList = [];

      this.industryId = '';
      this.scaleId = '';
      this.natureId = '';
      this.searchStr = '';

      if (val == 1) {
        this.pageNum = 2;
        this.loading = false;
      } else if (val == 2) {
        this.getCompany();
      } else if (val == 3) {
        this.getJob();
      }
    },

    schoolId: 'searchStudent',
    industryId: 'searchCompany',
    scaleId: 'searchCompany',
    natureId: 'searchCompany',
    sort: 'searchJob',
    cityId: 'searchJob',
    typeId: 'searchJob',
  },

  created() {
    if (!this.id) {
      Notify('分享链接失效');
      return;
    }

    this.getInfo();
    this.getIndustry();
    this.getNature();
    this.getTypeList();
    this.getCity();
  },

  methods: {
    clickDisabledActive(name, title, event, disabled) {
      if (disabled) {
        Dialog.alert({
          message:
            '此双选会已结束，人才大厅暂不支持查看，想要招募更多人才请至易校招企业版APP~',
        });
      }
    },

    getInfo() {
      getByUndersId({
        params: {
          undersId: this.id,
        },
      }).then((res) => {
        this.info = res;
        this.schoolId = res.schoolId;

        if (res.isUnion) {
          this.getSchool();
        }

        this.wxShareHandler({
          title: this.info.title,
          url: window.location.href,
          icon: 'https://yxzapp-dev.oss-cn-shanghai.aliyuncs.com/191598693566_.pic_hd.jpg',
          desc: this.info.sponsor,
        });
      });
    },

    getSchool() {
      getSchoolByUndersId({
        params: {
          undersId: this.id,
        },
      }).then((res) => {
        this.schoolList = res.map((item) => ({
          text: item.name,
          value: item.id,
        }));
      });
    },

    getIndustry() {
      getIndustryList().then((res) => {
        res.unshift({ id: '', name: '不限' });

        this.industryList = res.map((item) => ({
          text: item.name,
          value: item.id,
        }));
      });
    },

    getNature() {
      getNatureList().then((res) => {
        res.unshift({ id: '', name: '不限' });

        this.natureList = res.map((item) => ({
          text: item.name,
          value: item.id,
        }));
      });
    },

    getTypeList() {
      getPosInfoList().then((res) => {
        res.unshift({
          name: '不限',
          id: '',
          child: [
            {
              name: '不限',
              id: '',
            },
          ],
        });

        this.typeList = res.map((item) => ({
          text: item.name,
          id: item.id,
          children: item.child.map((item) => ({
            id: item.id,
            text: item.name,
          })),
        }));
      });
    },

    chooseType(data) {
      this.typeId = data.id;
      this.typeText = data.text;
      this.$refs.typeDown.toggle();
    },

    getCity() {
      getAreaList().then((res) => {
        this.cityList = res.map((item) => {
          if (Array.isArray(item.child) && item.child.length > 1) {
            item.child.unshift({
              id: item.id,
              name: '不限',
              parentId: null,
            });
          }

          return {
            text: item.name,
            id: item.id,
            children: item.child.map((item) => ({
              id: item.id,
              text: item.name,
            })),
          };
        });
      });
    },

    chooseCity(data) {
      console.log(data);
      const parent = this.cityList[this.parentCityIdx];

      this.cityType = data.id === parent.id ? 1 : 2;
      this.cityText = data.id === parent.id ? parent.text : data.text;
      this.cityId = data.id;
      this.$refs.cityDown.toggle();
    },

    chooseOpt() {
      this.searchJob();
      this.$refs.optDown.toggle();
    },

    searchStudent() {
      this.loading = true;
      this.finished = false;
      this.pageNum = 1;
      this.studentList = [];
      this.getStudent();
    },

    getStudent() {
      getStudentList({
        params: {
          pageNum: this.pageNum,
          pageSize: 10,
          schoolId: this.schoolId,
        },
      })
        .then((res) => {
          this.studentList = this.studentList.concat(res.records);
          this.pageNum += 1;
          this.loading = false;

          if (res.current >= res.pages) {
            this.finished = true;
          }
        })
        .catch(() => (this.loading = false));
    },

    searchCompany() {
      this.pageNum = 1;
      this.loading = true;
      this.finished = false;
      this.companyList = [];
      this.getCompany();
    },

    getCompany() {
      querySignupList({
        params: {
          undersId: this.id,
          pageNum: this.pageNum,
          pageSize: 10,
          industryId: this.industryId,
          scaleId: this.scaleId,
          natureId: this.natureId,
        },
      })
        .then((res) => {
          this.companyList = this.companyList.concat(res.records);
          this.pageNum += 1;
          this.loading = false;

          if (res.current >= res.pages) {
            this.finished = true;
          }
        })
        .catch(() => (this.loading = false));
    },

    searchJob() {
      console.log('aaa');
      this.pageNum = 1;
      this.loading = true;
      this.finished = false;
      this.jobList = [];
      this.getJob();
    },

    getJob() {
      getUndersProfession({
        params: {
          undersId: this.id,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          name: this.searchStr,
          sort: this.sort,
          cityType: this.cityType,
          cityId: this.cityId,
          typeType: 2,
          type: this.typeId,
          salary: this.salary,
          educationalBackground: this.educationalBackground,
        },
      })
        .then((res) => {
          this.jobList = this.jobList.concat(res.records);
          this.pageNum += 1;
          this.loading = false;

          if (res.current >= res.pages) {
            this.finished = true;
          }
        })
        .catch(() => (this.loading = false));
    },

    jump() {
      if (this.info.type == 1) {
        this.$router.push('/OnlineUndersOperation');
      } else {
        this.$router.push('/OfflineUndersOperation');
      }
    },

    dateText(val) {
      return moment(val).format('YYYY-MM-DD HH:mm');
    },

    scaleText(val) {
      const dict = {
        1: '10人以下',
        2: '10~20人',
        3: '20~50人',
        4: '50~200人',
        5: '200~1000人',
        6: '1000以上',
      };

      return dict[val] || '';
    },
  },

  components: {
    [Image.name]: Image,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [Tag.name]: Tag,
    [List.name]: List,
    [Icon.name]: Icon,
    [Button.name]: Button,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Sticky.name]: Sticky,
    [Search.name]: Search,
    [TreeSelect.name]: TreeSelect,
  },
};
</script>

<style lang="scss" scoped>
.under {
  width: 414px;
  margin: 0 auto;
  padding-top: 1px;
  min-height: 260px;
  background: #fff url('~@/assets/images/under-bg.png') no-repeat;
  background-position: top center;
  background-size: 100% 260px;
  font-size: 12px;

  .name {
    height: 2.7em;
    margin: 100px 16px 0;
    font-size: 20px;
    color: #fff;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .under-status {
    margin-top: 10px;
    padding-left: 10px;
    display: flex;
    justify-content: space-between;

    .el-image {
      display: block;
    }
  }

  .card {
    display: flex;
    margin: 15px 10px 0;

    .company,
    .job {
      flex: 1;
      box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.08);
      border-radius: 8px;
      background: #fff;
      color: #666;
      padding: 10px 2px 10px 8px;
      overflow: hidden;

      &.job {
        margin-left: 5px;
      }

      p {
        font-size: 0;

        span {
          font-size: 12px;
        }
      }

      p + p {
        margin-top: 10px;
        display: flex;
        overflow: hidden;
        align-items: baseline;

        span + span {
          margin-left: 2px;
          flex: 0 0 auto;
        }
      }

      .small {
        color: #aaa;
        font-size: 10px;
      }

      .big {
        font-size: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .tab {
    margin-top: 15px;

    :deep(.van-tab) {
      font-size: 12px;
    }

    :deep(.van-tab--active) {
      font-size: 125%;
    }
  }

  .detail {
    padding-bottom: 56px;

    .sponsor {
      border-top: 5px solid #faf7f7;

      .sponsor-line {
        margin: 15px 16px 0;
        padding-bottom: 15px;
        border-bottom: 0.5px solid rgba(0, 0, 0, 0.05);

        .title {
          color: #777;
        }

        .value {
          margin-top: 5px;
          word-break: break-all;
          white-space: normal;
        }
      }
    }

    .info {
      border-top: 5px solid #faf7f7;

      .block {
        margin: 15px 16px 0;
        padding-bottom: 15px;
        border-bottom: 0.5px solid rgba(0, 0, 0, 0.05);

        p {
          display: flex;
          align-items: center;
          color: #777;

          + p {
            margin-top: 10px;
          }

          span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .van-icon {
            margin-right: 5px;
          }
        }
      }

      .sign {
        margin: 20px 0;
        padding: 0 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .van-icon {
          margin: 0 20px;
          font-size: 22px;
        }

        .left {
          display: flex;
          flex-direction: column;
          color: #777777;
          color: 10px;

          .time {
            margin-top: 6px;
            font-size: 14px;
            color: #333;
          }
        }
      }
    }

    .invitation {
      border-top: 5px solid #faf7f7;

      .title {
        margin: 17px 16px 0;
        padding-bottom: 17px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      }
    }

    .button-wrapper {
      margin: 0 auto;
      max-width: 414px;
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      height: 56px;

      .button {
        box-sizing: border-box;
        height: 100%;
        padding: 0 16px;
        display: flex;
        align-items: center;
        background: #fff;

        .tip {
          display: flex;
          align-items: center;
          color: #ff5353;

          .van-icon {
            margin-right: 5px;
          }
        }

        .van-button {
          flex: 1;
          margin-left: 20px;
          height: 40px;
          background-color: #ff5353;

          &.stu-btn {
            background-color: #ffad00;
            border-color: #ffad00;
          }
        }
      }
    }
  }

  .student {
    .search {
      padding: 0 16px;
      height: 44px;
      display: flex;
      align-items: center;
      background: #fff;

      .school {
        display: flex;
        align-items: center;

        .down {
          margin-left: 2px;
          transform: rotate(90deg);
        }
      }
    }

    .van-list {
      padding: 1px 16px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      background: #faf7f7;

      .student-item {
        margin-top: 15px;
        width: 100%;
        background: #fff;
        border-radius: 8px;
        position: relative;

        .edu {
          position: absolute;
          top: 0;
          right: 10px;
          width: 20px;
        }

        .flex-box {
          display: flex;
          padding: 15px;
          padding-bottom: 5px;
        }

        .avatar {
          flex: 0 0 46px;
          width: 46px;
          height: 46px;
          position: relative;
          margin-right: 10px;

          .van-image {
            border: 1px solid rgba(0, 0, 0, 0.1);
          }

          .van-icon {
            position: absolute;
            bottom: 0;
            right: 0;
            font-size: 15px;
          }
        }

        .student {
          margin-right: 25px;
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          overflow: hidden;
        }

        .real-name {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 14px;
        }

        .major,
        .school-name {
          font-size: 12px;
          color: #777777;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .button {
          margin-top: 8px;
          height: 37px;
          border-top: 1px solid rgba(0, 0, 0, 0.05);
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding: 0 15px;

          .van-button {
            padding: 0 10px;
            margin-left: 15px;
            background: #ff5353;
            height: 24px;
            font-size: 12px;
          }
        }
      }
    }
  }

  .company {
    .van-list {
      padding: 1px 16px;
    }

    .company-item {
      display: flex;
      align-items: stretch;
      padding: 16px 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);

      .left {
        flex: 0 0 auto;
        border: 1px solid rgba(0, 0, 0, 0.05);
      }

      .right {
        margin-left: 10px;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow: hidden;

        .company-name {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .tags {
          overflow-x: auto;
          white-space: nowrap;
        }

        .van-tag {
          font-size: 10px;
          color: #777;
          background: #f6f6f6;
          white-space: nowrap;
        }
      }
    }
  }

  .job {
    .van-list {
      padding: 1px 16px;
    }

    .option-inner {
      .title {
        padding: 0 16px;
        line-height: 3em;
        font-size: 14px;
      }

      .opts {
        padding: 0 16px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .opt-item {
          flex: 0 0 30%;
          text-align: center;
          height: 2.5em;
          line-height: 2.5em;
          border: 1px solid rgba(0, 0, 0, 0.05);
          border-radius: 5px;
          margin-bottom: 15px;

          &.checked {
            background: #ff2a36;
            color: #fff;
          }
        }
      }

      .button {
        border-top: 1px solid rgba(0, 0, 0, 0.05);
        padding: 5px 16px;
      }
    }

    .job-item {
      padding: 16px 0;
      border-bottom: 0.5px solid rgba(0, 0, 0, 0.05);

      .job-1 {
        display: flex;
        justify-content: space-between;
        font-size: 14px;

        .left {
          display: flex;
          overflow: hidden;

          span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .van-tag {
            margin-left: 5px;
            flex: 0 0 auto;
            background-color: #ff2a36;
            font-size: 10px;
          }
        }

        .right {
          margin-left: 10px;
          flex: 0 0 auto;
          color: #ffad00;
        }
      }

      .job-2 {
        margin-top: 10px;

        span {
          + span {
            margin-left: 10px;
            color: #7f7f7f;
          }
        }
      }

      .job-3 {
        display: flex;
        margin-top: 10px;

        span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }

  :deep(.van-dropdown-menu) {
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.05);

    &.single .van-dropdown-menu__item {
      justify-content: flex-start;
    }

    .van-dropdown-menu__bar {
      box-shadow: none;
      padding: 0 16px;
    }

    .van-dropdown-menu__item + .van-dropdown-menu__item {
      margin-left: 10px;
    }

    .van-dropdown-menu__title {
      padding-left: 0;
      font-size: 12px;

      &:after {
        border-color: transparent transparent #333 #333;
      }
    }

    .van-cell__title {
      font-size: 12px;
    }
  }

  :deep(.van-list__finished-text),
  :deep(.van-list__loading) {
    flex: 0 0 100%;
    font-size: 12px;
  }

  .van-search .van-cell {
    font-size: 12px;
  }

  .van-search__action {
    color: #777;
  }

  :deep(.van-icon-search:before) {
    color: #aaa;
  }

  :deep(.van-sidebar-item) {
    font-size: 12px;
  }

  :deep(.van-tree-select__content) {
    font-size: 12px;

    .van-tree-select__item {
      font-weight: normal;
    }
  }

  .van-sidebar-item--select::before {
    width: 0;
  }

  :deep(.van-sticky) {
    margin: 0 auto;
    width: 414px;
  }

  :deep(.van-dropdown-item) {
    margin: 0 auto;
    width: 414px;
  }
}

@media screen and (max-width: 414px) {
  .under {
    width: 100%;

    .detail {
      .button-wrapper {
        max-width: 100%;
      }
    }

    :deep(.van-sticky) {
      width: 100%;
    }

    :deep(.van-dropdown-item) {
      width: 100%;
    }
  }
}
</style>

<style src="quill/dist/quill.core.css"></style>
